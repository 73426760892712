import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import Divider from './Divider'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    textAlign: 'center',
    marginBottom: (props) => (props.description ? 15 : 0),
    maxWidth: 280,
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'inherit',
      maxWidth: 675,
    },
  },
  sectionDescription: {
    textAlign: 'center',
    maxWidth: 350,
    color: 'rgba(115, 115, 115, 1)',
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'inherit',
      maxWidth: 600,
    },
  },

  sectionTitleDark: {
    textAlign: 'center',
    marginBottom: (props) => (props.description ? 15 : 0),
    maxWidth: 280,
    color: 'white',
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'inherit',
      maxWidth: 675,
    },
  },
  sectionDescriptionDark: {
    textAlign: 'center',
    maxWidth: 350,
    color: 'white',
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'inherit',
      maxWidth: 600,
    },
  },

  sectionLeftDescriptionDark: {
    textAlign: 'left',
    maxWidth: 350,
    color: 'white',
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 350,
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'inherit',
      maxWidth: 600,
    },
  },

  sectionLeftDescription: {
    textAlign: 'left',
    maxWidth: 350,
    color: 'rgba(115, 115, 115, 1)',
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 350,
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'inherit',
      maxWidth: 600,
    },
  },
}))
export default function SectionTitleBlock({
  title,
  description,
  darkTheme,
  bar,
  textAlignLeft,
}) {
  const classes = useStyles({ description })
  return (
    <>
      {bar && <Divider style={{ marginBottom: 12 }} />}
      <Typography
        variant='h2'
        className={darkTheme ? classes.sectionTitleDark : classes.sectionTitle}
      >
        {title}
      </Typography>
      {description &&
        (textAlignLeft ? (
          <Typography
            variant='body1'
            className={
              darkTheme
                ? classes.sectionLeftDescriptionDark
                : classes.sectionLeftDescription
            }
          >
            {description}
          </Typography>
        ) : (
          <Typography
            variant='body1'
            className={
              darkTheme
                ? classes.sectionDescriptionDark
                : classes.sectionDescription
            }
          >
            {description}
          </Typography>
        ))}
    </>
  )
}

SectionTitleBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  darkTheme: PropTypes.bool,
  bar: PropTypes.bool,
  textAlignLeft: PropTypes.bool,
}

SectionTitleBlock.defaultProps = {
  description: undefined,
  darkTheme: false,
  bar: false,
  textAlignLeft: false,
}
