import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
// import Divider from '../components/Divider'

import CustomerTargetIcon from '../assets/Business/Secure Comm Tools/Icons/customer_target.svg'
import CustomerReachIcon from '../assets/Business/Secure Comm Tools/Icons/customer_reach.svg'
import CustomerAcquisitionIcon from '../assets/Business/Secure Comm Tools/Icons/customer_acquisition.svg'
import CustomerDataIcon from '../assets/Business/Secure Comm Tools/Icons/customer_data.svg'
import ComingSoonIcon from '../assets/Business/Secure Comm Tools/Icons/coming_soon_banner.svg'

import CTABox from '../components/CTABox'
import Section from '../components/Section'
import SectionTitleBlock from '../components/SectionTitleBlock'
import FloatIconFeature from '../components/FloatIconFeature'
import CTALink from '../components/CTALink'

const HeroImage =
  '../assets/Business/Secure Comm Tools/Images/secure_comm_header.png'

const VerifiedCommImg =
  '../assets/Business/Secure Comm Tools/Images/verified_comm.png'
const AuthorizedCustomersImg =
  '../assets/Business/Secure Comm Tools/Images/pre_auth_customers2.png'

const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Components
   * ======================================= */
  sectionTitle: {
    marginBottom: 15,
    maxWidth: 280,

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 675,
    },
  },
  sectionDescription: {
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },
  /**
   * || Hero Section
   * ======================================= */
  hero: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: 500,
    top: -48, // Account for the header bar height
    paddingTop: 96,

    backgroundColor: '#F0F4F8',

    [theme.breakpoints.up('desktop')]: {
      height: 675,
      paddingTop: 120,
    },

    [theme.breakpoints.up('desktopXL')]: {
      justifyContent: 'space-evenly',
    },
  },
  heroImage: {
    display: 'none !important',
    [theme.breakpoints.up('desktop')]: {
      display: 'block !important',
      position: 'relative !important',

      width: 380,
      height: 'auto',
      top: 30,
      marginRight: 50,
    },

    [theme.breakpoints.up('desktopXL')]: {
      width: 500,
      height: 'auto',
      top: 80,
    },
  },
  heroContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    marginLeft: theme.gutters.mobile,
    marginRight: theme.gutters.mobile,

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',

      marginLeft: 50,
      marginRight: 50,
      width: 520,
    },
  },
  heroTitle: {
    color: 'black',

    textAlign: 'center',
    maxWidth: 275,

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 'unset',
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'left',
    },
  },
  heroDescription: {
    margin: '20px 0px',
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    textAlign: 'center',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 450,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 'unset',

      textAlign: 'left',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  comingSoonBanner: {
    marginBottom: 10,
  },

  /**
   * || Verified Communication Section
   * ======================================= */
  verifiedCommSection: {
    marginTop: 50,

    [theme.breakpoints.up('desktop')]: {
      marginTop: 140,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 280,
    },
  },
  verifiedCommFlexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row-reverse',
    },
  },
  verifiedCommImage: {
    width: '100%',
    marginTop: 50,
    maxWidth: 350,

    [theme.breakpoints.up('desktop')]: {
      width: '45%',
      marginTop: 0,
      maxWidth: 500,
    },
  },
  verifiedCommColumnRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',

      marginLeft: 70,

      textAlign: 'left',
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginLeft: 160,
    },
  },

  /**
   * || Help Desk Section
   * ======================================= */
  helpDeskSection: {
    marginTop: 100,

    background: '#FFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,

    [theme.breakpoints.up('desktop')]: {
      marginTop: 150,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 150,
      maxWidth: 1050,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  helpDeskTitleBlock: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    width: '100%',
    padding: '50px 30px',

    backgroundColor: '#F0F4F8',
    textAlign: 'center',
  },

  helpDeskFlexBox: {
    padding: '50px 30px',
    maxWidth: 500,

    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      // height: 600,
      padding: '50px 50px',
      maxWidth: 'unset',
    },
  },
  helpDeskImage: {
    width: '90%',
    marginTop: 30,
    maxWidth: 450,
    [theme.breakpoints.up('desktop')]: {
      minWidth: 450,
      marginLeft: 80,
      marginTop: 0,
    },
  },

  /**
   * || Customer Focused Experience Section
   * ======================================= */
  customerFocusSection: {
    marginTop: 100,

    [theme.breakpoints.up('desktop')]: {
      textAlign: 'center',
      marginTop: 180,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginBottom: 180,
    },
  },
  customerFocusGrid: {
    display: 'grid',
    gridTemplate: '1fr 1fr 1fr 1fr / 1fr',

    marginTop: 30,
    rowGap: 25,

    [theme.breakpoints.up('desktop')]: {
      gridTemplate: '1fr 1fr / 1fr 1fr',

      columnGap: 50,
    },

    [theme.breakpoints.up('desktopXL')]: {
      gridTemplate: '1fr/ 1fr 1fr 1fr 1fr',

      marginTop: 70,
      columnGap: 70,
    },
  },
  customerFocusGridItem: {
    maxWidth: 270,

    textAlign: 'center',

    '& svg': {
      width: 40,
      height: 'auto',

      [theme.breakpoints.up('desktop')]: {
        width: 60,
      },
    },
  },
}))

export default function CommunicationTools() {
  const classes = useStyles()
  return (
    <main>
      <section className={classes.hero}>
        <div className={classes.heroContent}>
          <ComingSoonIcon className={classes.comingSoonBanner} />
          <Typography variant='h1' className={classes.heroTitle}>
            Secure Communication Means Customer Confidence
          </Typography>

          <Typography variant='body1' className={classes.heroDescription}>
            Digital Identity goes beyond letting you do KYC or Authentication
            better than anyone else. With the power of Digital Identity,
            you&apos;re able to foster new relationships with your customers in
            ways your business couldn&apos;t before.{' \n\n'} CycurID&apos;s
            Secure Communication Tools enable you to keep your company safe,
            reach the people you want to reach, and create the best customer
            experiences possible.
            <br />
            <br />
            <b style={{ color: 'black' }}>
              Sign up now to get updated on when this feature will be released.
            </b>
          </Typography>
          <div className={classes.buttonContainer}>
            <CTALink
              className={classes.signUpButton}
              href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
              target='_blank'
              rel='noopener'
            >
              Sign Up Now
            </CTALink>
          </div>
        </div>
        <StaticImage
          src={HeroImage}
          alt='Imme app features'
          className={classes.heroImage}
          loading='eager'
          placeholder='blurred'
          quality={100}
        />
      </section>

      <Section className={classes.verifiedCommSection}>
        <div className={classes.verifiedCommFlexContainer}>
          <div className={classes.verifiedCommColumnRight}>
            <SectionTitleBlock
              title='Be Confident with Verified Communications'
              description={
                <>
                  CycurID <b style={{ color: 'black' }}>verifies</b> and
                  <b style={{ color: 'black' }}> authenticates</b> messages sent
                  through our communications network, eliminating the risk of
                  fraudsters impersonating you or anyone else.
                </>
              }
            />
            <FloatIconFeature
              title='Keep your Business Safe'
              description='1/3rd of all commercial data breaches involved criminal access through phishing schemes. Make sure your employees can be confident that messages from your company are legit and not fraudsters looking for a back door.'
            />
            <FloatIconFeature
              title='Foster Digital Trust'
              description='Stop scammers from successfully impersonating your company. Send encrypted, verified B2C communications through CycurID. Make sure your customers can trust the messages they receive from you are legitimate.'
            />
          </div>
          <StaticImage
            src={VerifiedCommImg}
            alt='Examples of CycurID secure and verified messages'
            className={classes.verifiedCommImage}
            placeholder='blurred'
            quality={100}
          />
        </div>
      </Section>

      <Section className={classes.helpDeskSection}>
        <div className={classes.helpDeskTitleBlock}>
          <SectionTitleBlock
            title='The Next-Gen Help Desk'
            description="Digital ID fundamentally changes the way you can build relationships with your customers. When trust is intrinsic and built-in to the system, you'll be able to deliver better services and experiences, building customer loyalty and boosting your bottom line."
          />
        </div>
        <div className={classes.helpDeskFlexBox}>
          <div>
            <Typography variant='h3'>Pre-Authenticated Customers</Typography>
            <Typography
              variant='body1'
              style={{
                color: '#737373',
                marginTop: 15,
                whiteSpace: 'pre-line',
              }}
            >
              Customer service can be a time-intensive process, largely due to
              having to validate and authenticate customers, store data, and
              access accounts.{' \n\n'} With Digital ID, customers needing
              assistance are pre-authenticated before the point of service,
              allowing your representatives or systems to cut to the chase and
              deliver faster, more efficient services.
            </Typography>
          </div>
          <StaticImage
            src={AuthorizedCustomersImg}
            alt='The CycurID app verification panel, including personal information including email, phone number and documents'
            className={classes.helpDeskImage}
            objectFit='fill'
            placeholder='blurred'
            quality={100}
          />
        </div>
      </Section>

      <Section className={classes.customerFocusSection}>
        <SectionTitleBlock
          title='Customer Focused Experience'
          description='CycurID’s authentication software enables you to spend your marketing dollars on verified customers who want your products and services.'
        />

        <div className={classes.customerFocusGrid}>
          <div className={classes.customerFocusGridItem}>
            <CustomerTargetIcon color='#2FA43B' />
            <Typography variant='h4' component='p'>
              Target specific potential customers without having to collect and
              sort demographic data.
            </Typography>
          </div>
          <div className={classes.customerFocusGridItem}>
            <CustomerReachIcon color='#2FA43B' />
            <Typography variant='h4' component='p'>
              Make sure the people you want to reach are already interested in
              your products.
            </Typography>
          </div>
          <div className={classes.customerFocusGridItem}>
            <CustomerAcquisitionIcon color='#2FA43B' />
            <Typography variant='h4' component='p'>
              Focus your customer acquisition costs by eliminating wasteful
              spending.
            </Typography>
          </div>
          <div className={classes.customerFocusGridItem}>
            <CustomerDataIcon color='#2FA43B' />
            <Typography variant='h4' component='p'>
              Get sensitive and costly customer data off of your servers by
              relying on Digital ID.
            </Typography>
          </div>
        </div>
      </Section>

      <CTABox
        title='Make sure your messages are heard.'
        description='Join CycurID and set up your business profile to fully leverage our communications platform. Market your services to the people that most want to use them.'
        style={{ marginTop: 100, marginBottom: 120 }}
      />
    </main>
  )
}
