import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import CheckIcon from '../assets/Business/Sanction List/Icons/circle_check.svg'
import CheckIconWhite from '../assets/Business/Sanction List/Icons/circle_check_white.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    display: 'flex',
    width: '100%',
    maxWidth: 500,
    textAlign: 'left',
    '& svg': {
      marginRight: 10,
      marginTop: 3,
      width: 30,
      height: 30,

      [theme.breakpoints.up('desktop')]: {
        width: 45,
        height: 45,
        marginTop: 0,
      },
    },
    '& div': {
      width: '100%',
      '& p': {
        marginTop: (props) => (props.title ? 15 : 0),
      },
    },
  },
  title: {
    marginTop: 10,
    marginBottom: 8,
  },
  titleDark: {
    marginTop: 10,
    marginBottom: 8,
    color: 'white',
  },
  description: {
    color: '#737373',
  },
  descriptionDark: {
    color: 'white',
  },
}))
export default function FloatIconFeature({ title, description, darkTheme }) {
  const classes = useStyles({ title, description })
  return (
    <div className={classes.container}>
      {darkTheme ? <CheckIconWhite /> : <CheckIcon />}
      <div>
        <Typography
          variant='h3'
          className={darkTheme ? classes.titleDark : classes.title}
        >
          {title}
        </Typography>
        <Typography
          variant='body2'
          className={darkTheme ? classes.descriptionDark : classes.description}
        >
          {description}
        </Typography>
      </div>
    </div>
  )
}

FloatIconFeature.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  darkTheme: PropTypes.bool,
}

FloatIconFeature.defaultProps = {
  title: undefined,
  darkTheme: false,
}
