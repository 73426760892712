import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: (props) =>
      props.gutterVariant === 'padding' ? theme.gutters.mobile : '0px',
    paddingRight: (props) =>
      props.gutterVariant === 'padding' ? theme.gutters.mobile : '0px',
    marginLeft: (props) =>
      props.gutterVariant === 'margin' ? theme.gutters.mobile : '0px',
    marginRight: (props) =>
      props.gutterVariant === 'margin' ? theme.gutters.mobile : '0px',
  },
}))
export default function Section({ children, className, gutterVariant }) {
  const classes = useStyles({ gutterVariant })
  return (
    <section className={`${classes.section} ${className}`}>{children}</section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  gutterVariant: PropTypes.oneOf(['margin', 'padding', 'none']),
}

Section.defaultProps = {
  className: '',
  gutterVariant: 'margin',
}
