import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  greenBar: {
    borderTop: '3px solid #2FA43B',
    border: 'none',
    width: 40,
    margin: 0,
  },
}))
export default function Divider({ style, className }) {
  const classes = useStyles()
  return <hr className={`${classes.greenBar} ${className}`} style={style} />
}

Divider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
}

Divider.defaultProps = {
  style: {},
  className: '',
}
