import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import routes from '../routes'
import CTALink from './CTALink'
import Divider from './Divider'

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.14)',
    borderRadius: 10,
    padding: 30,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('tablet')]: {
      padding: 35,
    },

    margin: '0px 25px',
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 800,
      padding: '40px 50px',
      margin: '0px auto 0px auto',
    },
    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: 1050,
      //   padding: '80px 160px',
      margin: '0px auto 0px auto',
    },
  },
  containerNoShadow: {
    background: '#FFFFFF',
    borderRadius: 10,
    padding: 30,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('tablet')]: {
      padding: 35,
    },

    margin: '0px 25px',
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 800,
      padding: '40px 50px',
      margin: '0px auto 0px auto',
    },
    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: 1050,
      //   padding: '80px 160px',
      margin: '0px auto 0px auto',
    },
  },
  title: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 'bold',

    [theme.breakpoints.up('tablet')]: {
      fontSize: 28,
    },
    [theme.breakpoints.up('desktop')]: {
      //   maxWidth: 'unset',
      fontSize: 30,
    },

    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 32,
    },
  },
  description: {
    textAlign: 'left',
    color: '#737373',
    marginTop: 10,
    marginBottom: 20,
    // maxWidth: 500,
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('desktopXL')]: {
      marginBottom: 30,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  leftButton: {
    marginRight: 15,
    width: 130,
    [theme.breakpoints.up('tablet')]: {
      width: 'unset',
    },
  },
  rightButton: {
    width: 130,
    [theme.breakpoints.up('tablet')]: {
      width: 'unset',
    },
  },
  titleAndBodyContainer: {},
}))
export default function CTABox({
  style,
  className,
  title,
  description,
  buttons,
  bar,
  noShadow,
}) {
  const classes = useStyles()

  const getLinkProps = (buttonText) => {
    switch (buttonText) {
      case 'Sign Up Now': {
        return {
          route: process.env.GATSBY_DASHBOARD_SIGNUP_LINK,
          target: '_blank',
          rel: 'noopener',
        }
      }
      case 'Email Us Now':
      case 'Contact Us': {
        return { route: routes.CONTACT, target: '_self', rel: '' }
      }

      default: {
        return { route: routes.HOME, target: '_self', rel: '' }
      }
    }
  }
  return (
    <div
      style={style}
      className={
        noShadow
          ? classes.containerNoShadow
          : `${classes.container} ${className}`
      }
    >
      <div className={classes.titleAndBodyContainer}>
        {bar && <Divider style={{ marginBottom: 15 }} />}
        <Typography variant='h2' className={classes.title}>
          {title}
        </Typography>
        <Typography variant='body2' className={classes.description}>
          {description}
        </Typography>
      </div>
      {buttons === 'Visit Imme' && (
        <div className={classes.buttonContainer}>
          <CTALink className={classes.leftButton} href='https://imme.io/'>
            {buttons}
          </CTALink>
        </div>
      )}
      {JSON.stringify(buttons) ===
        JSON.stringify(['Sign Up Now', 'Contact Us']) && (
        <div className={classes.buttonContainer}>
          {buttons.map((buttonText, index) => {
            const linkProps = getLinkProps(buttonText)
            return (
              <CTALink
                key={buttonText}
                className={classes.leftButton}
                variant={index % 2 === 0 ? 'fill' : 'outline'}
                href={linkProps.route}
                target={linkProps.target}
                rel={linkProps.rel}
              >
                {buttonText}
              </CTALink>
            )
          })}
        </div>
      )}
    </div>
  )
}
CTABox.propTypes = {
  /**
   * Additional styles to apply to the container
   */
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  /**
   * Additional classnames to apply to container
   */
  className: PropTypes.string,

  /**
   * Title for the component
   */
  title: PropTypes.string,

  /**
   * Description text for the component
   */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * For each string in the array, a button will be created with the given label. Alternates fill and outline style
   */
  buttons: PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ),
  bar: PropTypes.bool,
  noShadow: PropTypes.bool,
}
CTABox.defaultProps = {
  style: {},
  className: '',
  title: 'A Title',
  description: 'Here is a description',
  buttons: [],
  bar: false,
  noShadow: false,
}
